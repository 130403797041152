import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/world-patient-safety/1.jpeg';
import blogd2 from '../../assets/images/2024/world-patient-safety/2.jpeg';
import blogd3 from '../../assets/images/2024/world-patient-safety/3.jpeg';
import blogd4 from '../../assets/images/2024/world-patient-safety/4.jpeg';
import blogd5 from '../../assets/images/2024/world-patient-safety/5.jpeg';
import blogd6 from '../../assets/images/2024/world-patient-safety/6.jpeg';
import blogd7 from '../../assets/images/2024/world-patient-safety/7.jpeg';
import blogd8 from '../../assets/images/2024/world-patient-safety/8.jpeg';
import blogd9 from '../../assets/images/2024/world-patient-safety/9.jpeg';
import blogd10 from '../../assets/images/2024/world-patient-safety/10.jpeg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                {/* <img src={blogd1} alt='' /> */}
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  PRESS RELEASE
                  <br></br>
                  <br></br>
                  Patient Access for a Cure for Sickle Cell Disease Becomes a
                  Reality
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lagos, Nigeria, September 20th, 2024—The Lagos University
                  Teaching Hospital (LUTH), in partnership with the Sickle Cell
                  Foundation Nigeria (SCFN), has worked for several years to
                  establish a high-quality, safe bone marrow transplant
                  programme that meets international standards for the cure of
                  sickle cell disease and is accessible to Nigerians at home.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    {/* <img src={blogd2} height={370} /> */}
                  </div>
                  <div className='col-lg-6'>
                    {/* <img src={blogd3} height={370} /> */}
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Nigeria has the most significant burden of Sickle Cell Disease
                  worldwide. Individuals affected with the disease suffer
                  life-threatening and excruciating complications from early
                  childhood, serious damage to their organs, and reduced life
                  expectancy. The establishment of comprehensive care
                  programmes, including newborn screening, penicillin
                  prophylaxis, and Transcranial Doppler screening in children to
                  identify those at risk of stroke, followed by using chronic
                  blood transfusion therapy and the increasing use of
                  hydroxyurea therapy, has improved the proportion of children
                  surviving into adulthood but has not improved the proportion
                  of adults living to older age, especially for the most
                  severely affected. Bone marrow transplant using a donor from a
                  family member is an established cure for this disease, first
                  used more than 30 years ago. Bone marrow transplant is
                  associated with known complications such as infection and
                  graft-versus-host disease (when donor cells can attack the
                  patient), infertility, and even death. However, this procedure
                  has been much improved over the last 20 years to ensure good
                  outcomes and limit complications. Bone marrow transplant is
                  now an approved therapy for children and adults with severe
                  sickle cell disease. Bone marrow transplant is a complex
                  procedure requiring a multidisciplinary team approach and
                  involves treatment and close follow-up for approximately 12
                  months. The complexity and costs have severely limited those
                  who can obtain this treatment, and most have sought this
                  treatment outside Nigeria, which causes severe hardship for
                  patients and families, only to come home with no local
                  expertise for follow-up.
                  <br></br>
                  <br></br>
                  Recognizing the gaps in care for individuals affected by the
                  disease in Nigeria, the leadership of the Sickle Cell
                  Foundation Nigeria (SCFN) and Lagos University Teaching
                  Hospital (LUTH) established a bone marrow transplant programme
                  at LUTH. In preparation for a locally adapted and
                  cost-effective bone marrow transplant programme in Nigeria, a
                  post-transplant clinic was initially established in 2019, a
                  first in sub-Saharan Africa, to provide post-transplant care
                  to patients who had travelled to other countries for bone
                  marrow or stem cell transplants, thereby developing clinical
                  expertise in post-transplant care. The programme also
                  established a Stem Cell Processing Laboratory, the first in
                  West Africa. This LUTH/SCFN bone marrow transplant programme
                  consists of a high-level multidisciplinary team that includes
                  paediatric and adult haematologists, including Dr. Seye
                  Akinsete (Team lead) and Drs. Ugonna Fakile, Olufunto
                  Kalejaiye, Yusuf Adelabu, and Professor Edamisan Temiye (the
                  Programme Manager), blood transfusion and stem cell processing
                  lab support (Professor Titi Adeyemo and Dr. Ann Ogbenna), bone
                  marrow-transplant nurses, dieticians, social workers,
                  pharmacists, psychiatrists, anaesthesiologists, nurses at the
                  KidsOR (theatre) and other ancillary personnel. The program
                  has been guided by the scientific expertise and leadership of
                  Professor Adetola Kassim of Vanderbilt University Medical
                  Center, who joined the program in 2018. To further strengthen
                  their expertise in the program, Prof Josu de la Fuente from
                  the Imperial College London Healthcare NHS Trust UK joined the
                  team in 2022 as a resource person and assisted with the
                  paediatric aspects of the programme. Transplant Nursing has
                  been supported by the Worldwide Network for Blood and Marrow
                  Transplantation (WBMT), which offers both in-person and
                  virtual training at periodic intervals.
                  <br></br>
                  <br></br>
                  Importantly, the role of Sickle Cell Foundation Nigeria (SCFN)
                  in this Public Private Partnership has been invaluable -
                  providing administrative and project management oversight
                  under the leadership of the CEO, Dr. Annette Akinsete. The
                  vision and invaluable role of Prof. Olu Akinyanju, OON-Founder
                  of SCFN is also well recognized.
                  <br></br>
                  <br></br>
                  The first set of patients are currently undergoing bone marrow
                  transplants at Lagos University Teaching Hospital and were
                  admitted in the last week of August 2024. They received a
                  treatment regimen of exchange blood transfusions, chemotherapy
                  and anti-infective prophylaxis to prepare them for the
                  infusion of bone marrow stem cells that were harvested from
                  family donors, processed and given this week (17th and 19th
                  September 2024). Both patients are currently undergoing
                  immediate post-transplant care within the transplant unit at
                  Lagos University Teaching Hospital.
                  <br></br>
                  <br></br>
                  We want to express our appreciation to patients and families,
                  the Management of the Lagos University Teaching Hospital,
                  especially the Chief Medical Director, Professor Wasiu Lanre
                  Adeyemo, and his predecessors (Prof. Chris Bode and Prof. Akin
                  Osibogun), the Sickle Cell Foundation Nigeria (The CEO, Dr.
                  Annette Akinsete and board of directors under the able
                  leadership of its Chairman - Chief Tunde Afolabi, MFR), Lagos
                  State Government, Chevron/NNPCL, collaborative and technical
                  partners, and other dedicated staff who have been working
                  around the clock to ensure the success of this programme.
                  <br></br>
                  <br></br>
                  Finally, we wish to express our profound gratitude to the
                  Federal Ministry of Health & Social Welfare (FMOH&SW) under
                  the able leadership of the Coordinating Minister of Health and
                  Social Welfare (Prof. Muhammed Ali Pate), and the Honourable
                  Minister of State for Health (Dr Tunji Alausa) for their
                  leadership, support and encouragement throughout this long
                  journey.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd4} height={370} /> */}
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    {/* <img src={blogd5} height={370} /> */}
                  </div>
                  <div className='col-lg-6'>
                    {/* <img src={blogd6} height={370} /> */}
                  </div>
                  <div className='col-lg-6'>
                    {/* <img src={blogd8} height={370} /> */}
                  </div>
                  <div className='col-lg-6'>
                    {/* <img src={blogd7} height={370} /> */}
                  </div>
                  {/* <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div> */}
                  {/* <div className='col-lg-6'>
                    <img src={blogd10} height={370} />
                  </div> */}
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span> */}
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd9} height={370} /> */}
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
